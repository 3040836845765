import { FlashNotificationHandler, NotificationProvider } from '@dropscan/notifications'

import { DesignSystemProvider } from '@dropscan/ds/DesignSystemProvider'
import { Theme } from '@dropscan/ds/themeTypes'

export const AppShell: React.FC<{ initialTheme?: Theme; children?: React.ReactNode }> = ({
  children,
  initialTheme,
}) => (
  <NotificationProvider>
    <FlashNotificationHandler />
    <DesignSystemProvider initialTheme={initialTheme}>{children}</DesignSystemProvider>
  </NotificationProvider>
)
