/* eslint-disable @typescript-eslint/naming-convention */

export {}

declare const ASSET_HOST: string | undefined

// Tell webpack to load assets through the asset host if one is defined
if (typeof ASSET_HOST === 'string') {
  __webpack_public_path__ = `${ASSET_HOST}${__webpack_public_path__}`
}
