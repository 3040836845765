export const PriorityRank = {
  success: 1,
  // info: 0,
  important: 2,
  error: 3,
}

export type Priority = keyof typeof PriorityRank
export interface NotificationOptions {
  priority: Priority
  content: React.ReactNode
  id?: string
  buttonLabel?: string
  onDismiss?: () => void
  sticky?: boolean
}

/** Await a promise and then add a notification based on success/failure. */
type NotifyResultFn = <T>(
  promise: Promise<T>,
  messages: {
    success: string
    successPriority?: Priority
    error?: string | ((error: Error) => string | undefined)
  },
) => Promise<T>

export interface Notifier {
  now: (opts: NotificationOptions) => () => void
  after: NotifyResultFn
}

export interface NotificationRenderProps {
  priority: Priority
  id: string
  sticky: boolean
  onDismiss: () => void
  children: React.ReactNode
}
