import { CSSObject } from '@emotion/react'
import * as React from 'react'

import { InputProps } from '@dropscan/forms'

import { Input } from '../forms/Input'
import { InputVariantProps } from '../forms/inputVariants'
import Label from './Label'
import PasswordStrength from './PasswordStrength'

interface Props extends Partial<InputProps<string>>, InputVariantProps {
  /** when true, this will render a <textarea> and not an <input */
  labelText?: React.ReactNode
  maxLength?: number
  required?: boolean
  showStrength?: boolean
  styles?: CSSObject
  placeholder?: string
  autoComplete?: string
}

interface State {
  passwordScore?: number
}

/**
 * A `TextInput` that can show a password strength meter with the `showStrength` prop.
 */
export default class PasswordInput extends React.PureComponent<Props, State> {
  state: State = {}

  render() {
    const {
      labelText,
      spacing,
      validationState,
      maxLength,
      styles,
      error,
      autoComplete,
      showStrength,
      onChangeValue, // don't forward this to the underlying <input>
      ...inputProps
    } = this.props
    const stylingProps = { spacing, validationState, styles }

    return (
      <Label {...stylingProps} css={styles}>
        {labelText && (
          <span>
            {labelText} {this.props.required === false ? <small>• optional</small> : null}
          </span>
        )}
        <Input
          type="password"
          id={inputProps.name ? inputProps.name.replace(/[\[\]']+/g, '_') : ''}
          maxLength={maxLength}
          autoComplete={autoComplete}
          {...inputProps}
          {...stylingProps}
          onChange={this.handleChange}
        />
        {showStrength && typeof this.state.passwordScore === 'number' && (
          <PasswordStrength score={this.state.passwordScore} />
        )}
        {validationState === 'error' && error}
      </Label>
    )
  }

  private handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    const value = evt.currentTarget.value
    if (this.props.onChangeValue) {
      this.props.onChangeValue(value)
    }
    if (this.props.showStrength) {
      if (value.length) {
        this.checkPasswordStrength(value)
      } else {
        this.setState({ passwordScore: undefined })
      }
    }
  }

  private checkPasswordStrength(value: string) {
    import('zxcvbn').then(
      zxcvbn => {
        const result = zxcvbn.default(value)
        this.setState({
          passwordScore: result.score,
        })
      },
      error => {
        console.error('Loading zxcvbn failed, password strength will not be checked', error)
      },
    )
  }
}
